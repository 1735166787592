@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/rsuite/dist/styles/rsuite-default.css';
@import 'colors.scss';
@import 'rsuite-common.scss';


body {
    font-family: Roboto, Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* main */

.rs-panel {
    position: relative;
}

.minWidth-wrapper{
    margin-left: 12px;
}

.columns-picker-icon-show {
    padding-top: 12px;
    padding-left: 10px;
    color: #ffffff;
}

.white-space-pre {
    white-space: pre;
}

.rs-picker-menu .rs-checkbox-wrapper .rs-checkbox-inner::before {
    background: transparent;
    border-radius: 5px;
    border: 1px solid #949494
}

.rs-picker-value-count {
    background: #8256C8;
}


.z-index-dropdown {
    z-index: 1000;
}

span.rs-tree-node-label{
    width: 100%;
}
.rs-input-number.without-buttons {
    overflow: hidden;
}
.rs-input-number.without-buttons .rs-input-number-btn-group-vertical {
    display: none;
}
div.rs-modal-wrapper[role="dialog"] {
    width: 100% !important;
    height: 100% !important;
}
div.rs-modal[role="dialog"] {
    margin: 15vh auto 0 auto !important;
}
/* #main */




/* api page */
.app-client {

    .api_menu {
        .rs-tree-node-dragging {
            outline: none;
        }
        .rs-tree-node {
            margin-bottom: 0;
        }
        .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content {
            padding: 0;
            border: none;
            margin: 0;
        }
        .rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content {
            color: inherit !important;
            background: transparent !important;
            font-weight: 400 !important;
        }
    }
}

.app-client {
    .rs-input-group-addon {
        background-color: #ffffff;
        color: var(--color-grey);
    }
    .rs-input-group:not(.rs-input-group-inside) {
        border: 1px solid var(--color-grey);
    }
    .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled),
    .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled) {
        background-color: #ffffff;
    }
}





.rs-picker-select-menu-item b {
    color: #8e8e93;
    font-weight: 400;
}

.rs-picker-select-menu-item-active b {
    color: #1675e0;
    font-weight: 400;
}

.rs-picker-toggle-value b {
    color: #8e8e93;
    font-weight: 400;
}

.ReactCollapse--collapse {
    transition: height 0.3s ease-out;
}

.rs-form-control-wrapper span.rs-error-message {
    z-index: 10;
}

.hide-dropdown {
    display: none! important;
}

.panel__noShadow .rs-panel-body {
    box-shadow: none;
}



.picker-menu-trunks {
    font-size: 12px;
}

.rs-input {
    min-width: 1px;
}

.rs-picker-menu.picked-menu-mobile {
    top: auto !important;
    left: 3% !important;
    right: auto !important;
    bottom: 3% !important;
    width: 94% !important;
    min-width: 1px !important;
}




body,
.rs-content {
    background: var(--color-bg);
}

div.rs-navbar.rs-navbar-inverse {
    background-color:var(--color-brand1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.16);
    position: relative;
}

button.rs-btn-default.rs-btn-blue,
button.rs-btn.rs-btn-primary {
    background-color:var(--color-brand1) !important;
}

div .rs-btn-primary:active:hover,
div .rs-btn-primary.rs-btn-active:hover,
div .rs-open > .rs-dropdown-toggle.rs-btn-primary:hover,
div .rs-btn-primary:active:focus,
div .rs-btn-primary.rs-btn-active:focus,
div .rs-open > .rs-dropdown-toggle.rs-btn-primary:focus,
div .rs-btn-primary:active.focus,
div .rs-btn-primary.rs-btn-active.focus,
div .rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
    background-color:var(--color-brand1);
}

a.rs-btn-ghost {
    color:var(--color-brand1);
    border-color: var(--color-brand1);
}

a.rs-btn-primary {
    background-color: var(--color-brand1);
}

.white-panel {
    margin-top: 10px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding: 10px;
    min-height: 80vh;
}

body .rs-input-group-addon {
    background-color: transparent;
    color: #939191;
}

body .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
body .rs-checkbox-indeterminate .rs-checkbox-wrapper .rbs-checkbox-inner::before {
    border-color: var(--color-brand2);
    background-color: var(--color-brand2);
}










.minWidth-wrapper,
.allocated-wrapper {
    position: relative;
}
.minWidth-wrapper .rs-error-message-placement-bottom-end,
.minWidth-wrapper .rs-error-message-placement-top-end,
.allocated-wrapper .rs-error-message-placement-bottom-end,
.allocated-wrapper .rs-error-message-placement-top-end {
    right: 0;
}

.minWidth-wrapper .rs-error-message-placement-top-start,
.minWidth-wrapper .rs-error-message-placement-top-end,
.allocated-wrapper .rs-error-message-placement-top-start,
.allocated-wrapper .rs-error-message-placement-top-end {
    top: -1px;
}

.minWidth-wrapper .rs-error-message-wrapper,
.allocated-wrapper .rs-error-message-wrapper {
    position: absolute;
}

.minWidth-wrapper .rs-error-message-placement-bottom-end .rs-error-message,
.minWidth-wrapper .rs-error-message-placement-top-end .rs-error-message,
.allocated-wrapper .rs-error-message-placement-bottom-end .rs-error-message,
.allocated-wrapper .rs-error-message-placement-top-end .rs-error-message {
    right: 0;
}

.minWidth-wrapper .rs-error-message-placement-top-start .rs-error-message-show,
.minWidth-wrapper .rs-error-message-placement-top-end .rs-error-message-show,
.allocated-wrapper .rs-error-message-placement-top-start .rs-error-message-show,
.allocated-wrapper .rs-error-message-placement-top-end .rs-error-message-show {
    -webkit-animation-name: errorMessageSlideUpIn;
    animation-name: errorMessageSlideUpIn;
}

.minWidth-wrapper .rs-error-message-placement-top-start .rs-error-message,
.minWidth-wrapper .rs-error-message-placement-top-end .rs-error-message,
.allocated-wrapper .rs-error-message-placement-top-start .rs-error-message,
.allocated-wrapper .rs-error-message-placement-top-end .rs-error-message {
    bottom: 0;
}

.minWidth-wrapper .rs-error-message.rs-error-message-show,
.allocated-wrapper .rs-error-message.rs-error-message-show {
    display: block ;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

.minWidth-wrapper .rs-error-message,
.allocated-wrapper .rs-error-message {
    position: absolute;
    padding: 4px 8px;
    background-color: #fff;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    -webkit-filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
    filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
    z-index: 5;
    color: #f44336;
    font-size: 12px;
    display: none;
    white-space: nowrap;
}


/* Dropdown fix */

.rs-dropdown:after {
    content: "";
    cursor: pointer;
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}
.rs-dropdown.rs-dropdown-open:after {
    display: block;
}