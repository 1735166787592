
.edit-profile-input-wrapper {
    width: 100%;
    max-width: 300px;
    min-width: 140px;
}

.edit-profile-input {
    max-width: 300px;
    min-width: 140px;
}