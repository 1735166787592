.rs-btn {
    padding: 8px 12px;
}

.rs-btn-xs {
    padding: 2px 8px;
}

.rs-btn-sm {
    padding: 6px 10px;

    .app-client & {
        padding-bottom: 5px;
        padding-top: 5px;
    }
}

.rs-btn-lg {
    line-height: 1.42857143em;
    font-size: 14px;
    padding: 12px 34px;
}

.rs-input-number-touchspin-down,
.rs-input-number-touchspin-up {
    padding: 0;
}