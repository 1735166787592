.wrapper {
    display: flex;
    margin: 0 -6px;
    padding-bottom: 20px;
    flex-wrap: wrap;
}

@media (min-width: 1200px) {
    .wrapper {
        padding-bottom: 40px;
    }
    :global(.rs-modal) .wrapper {
        padding-bottom: 0;
        margin: 0;
    }
}

.form {
    display: inline-block;
    vertical-align: top;
}

.form :global(.rs-form-group),
.form :global(.rs-form-control-wrapper) {
    margin: 0 !important;
}

.form,
.pagination {
    padding: 0 6px;
}

.form input {
    min-width: 10px;
}