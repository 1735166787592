/* rsuite table custom styles */

.rs-table-scrollbar-handle {

    .app-client & {
        background-color: #c5c6c7;
    }
}

.rs-table-cell-group-fixed-right {

    .app-client & {
        margin-left: 0;
    }
}