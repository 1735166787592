.rs-uploader-trigger input[type='file'] {
    width: 0;
}
.rs-uploader-file-item-disabled {
    display: none
}
.auth-number-form {
    margin-top: 0;
}
.auth-number-form .rs-error-message.rs-error-message-show {
    display: none;
}
.auth-number-form .rs-help-block .rs-table-auth-numbers-input-error {
    color: red;
    position: absolute;
    top: -2px;
    right: 20px;
    background: white;
    padding: 0 8px;
    border-radius: 10px;
    font-size: 12px;
}
.auth-number-form .auth-number-input {
    /*margin-top: 5px;*/
}
.auth-number-add-input {
    margin-top: 0;
    width: 100%;
}
.tableFilters.tableFilters__form .rs-table-cell-content {
    display: flex;
}
.tableFilters.tableFilters__form .rs-table-cell-content .rs-form-control-wrapper {
    flex: 1;
}
.tableFilters.tableFilters__form .rs-table-cell-content .rs-form-group {
    flex: 1;
}
.auth-number-form .rs-form-control-wrapper.read-only .rs-picker-toggle-caret {
    color: lightgray;
    cursor: move;
}
.auth-number-form .rs-table-auth-numbers-copy-button {
    top: 11px;
    right: 18px;
    width: 24px;
    height: 24px;
    padding: 0;
    position: absolute;
    background: #9a71d9;
}
.auth-number-form > .rs-table-auth-numbers-textarea-hidden {
    width: 0;
    height: 0;
    resize: none;
    border: 0;
    /*visibility: hidden*/
}
.auth-add-number-form {
    margin-top: 5px;
}
.auth-add-number-form .rs-form-group {
    height: 0;
    display: inline-block;
}
.auth-add-number-form .rs-error-message.rs-error-message-show {
    display: none;
}
.auth-add-number-form .rs-help-block {
    position: relative;
}
.auth-add-number-form .rs-help-block .rs-table-auth-numbers-input-error {
    color: red;
    position: absolute;
    top: -44px;
    right: 20px;
    padding: 0 8px;
    border-radius: 10px;
    font-size: 12px;
    background: white;
}

.rs-btn-default.rs-btn-green.rs-table-auth-numbers-single-button, .rs-btn-default.rs-btn-red.rs-table-auth-numbers-single-button {
    width: 30px;
    height: 30px;
    padding: 0
}
.rs-btn-default.rs-btn-green.rs-table-auth-numbers-buttons, .rs-btn-default.rs-btn-red.rs-table-auth-numbers-buttons {
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 10px;
}
.rs-btn-default.rs-btn-green.rs-table-auth-numbers-buttons.rs-table-auth-numbers-green-button,
.rs-btn-default.rs-btn-green.rs-table-auth-numbers-single-button.rs-table-auth-numbers-green-button {
    background: #20BA88;
}
.rs-btn-default.rs-btn-green.rs-table-auth-numbers-buttons.rs-table-auth-numbers-green-button:hover,
.rs-btn-default.rs-btn-green.rs-table-auth-numbers-single-button.rs-table-auth-numbers-green-button:hover {
    background: #20CA88;
}
.rs-btn-default.rs-btn-green.rs-table-auth-numbers-buttons.rs-table-auth-numbers-red-button,
.rs-btn-default.rs-btn-green.rs-table-auth-numbers-single-button.rs-table-auth-numbers-red-button {
    background: #F8432F;
}
.rs-btn-default.rs-btn-green.rs-table-auth-numbers-buttons.rs-table-auth-numbers-red-button:hover,
.rs-btn-default.rs-btn-green.rs-table-auth-numbers-single-button.rs-table-auth-numbers-red-button:hover {
    background: #939191;
}
.rs-uploader-file-item.rs-uploader-file-item-text {
    display: none;
}