.unlogin_loader {
    margin-top: 80px;
}
.unlogin_confirm_payment_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90vw;
    padding-top: 50px
}
.unlogin_confirm_payment_content .link {
    padding-top: 20px;
    padding-bottom: 20px;
}
.login_confirm_payment_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.login_confirm_payment_content .link {
    padding-top: 20px;
}
.confirm__cell div .rs-icon.rs-icon-check-square {
    color: green;
    font-size: 20px;
}
.confirm__cell div .rs-icon.rs-icon-collasped {
    color: var(--color-grey);
    font-size: 18px;
}
