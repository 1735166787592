.cleanable-custom-field {
    box-shadow: 0 0 0 2px transparent;
    transition: box-shadow .3s ease-in-out, border-color ease-in-out .3s !important;
}
.cleanable-custom-field.has-changed {
    box-shadow: 0 0 3px 2px rgba(255, 156, 13, 0.8);
}
.cleanable-custom-field {
    max-width: 120px;
    width: 100% !important;
    transition: color 1.5s ease-in-out !important;
}
.cleanable-custom-field.has-modified {
    color: rgb(36, 156, 80);
}