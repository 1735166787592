.versionLabel{
    width: 55px;
    height: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--color-brand1);
    color: white;
    border-top-right-radius: 10px;
    text-align: center;
}

:global(.rtl) .versionLabel {
    left: auto;
    right: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 10px;
}