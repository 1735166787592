.rtl.app-client {

    direction: rtl;
    text-align: right;

    .rs-navbar-nav {
        float: right;
    }

    .rs-navbar-nav.rs-navbar-right {
        float: left;
    }

    .rs-navbar-header {
        float: right;

        .custom-dropdown-menu {
            margin-right: 0 !important;
            margin-left: 24px !important;

            .custom-dropdown-menu-toggle {
                padding-right: 12px;
                padding-left: 32px;
            }

            .custom-dropdown-menu-toggle-text {
                text-align: right !important;
            }
        }

        .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
            right: auto;
            left: 12px;
            margin-left: 0;
            margin-right: 4px;

            @media (max-width: 810px) {
                top: 18px;
            }
        }

        @media (max-width: 810px) {
            height: auto;
        }
    }

    .rs-dropdown-menu .rs-dropdown-item-content > .rs-icon,
    .rs-nav-item > .rs-nav-item-content > .rs-icon {
        margin-right: 0;
        margin-left: 6px;
    }

    .rs-dropdown-menu .rs-dropdown-item-content {
        text-align: right;
    }
    
    .rs-modal-header .rs-modal-header-close {
        left: 20px;
        right: auto;
    }
    
    .rs-notification-item-close {
        left: 8px;
        right: auto;
    }

    .rs-message.rs-message-has-icon .rs-message-container {
        padding-left: 20px;
        padding-right: 42px;
    }


    .rs-message.rs-message-has-icon.rs-message-has-title .rs-message-container {
        padding-right: 54px;
    }

    .rs-message .rs-message-icon-wrapper {
        left: auto;
        right: 20px;
    }

    .rs-modal-header {
        padding-left: 20px;
        padding-right: 0;
    }

    .rs-modal-footer {
        text-align: left;
    }

    .rs-modal-footer .rs-btn + .rs-btn {
        margin-left: 0;
        margin-right: 10px;
    }

    .rs-btn-sm.rs-btn-icon > .rs-icon {
        right: 0 !important;
        left: auto !important;
    }

    .rs-pagination {
        padding-right: 0;
    }

    .rs-form-inline .rs-form-group .rs-form-control-wrapper {
        margin-left: 0;
        margin-right: 12px;
    }

    .rs-form-inline > *, 
    .rs-form-inline > .rs-btn {
        margin-left: 30px;
        margin-right: 0;
    }

    .rs-btn-toolbar > :not(:first-child):not(.rs-btn-block) {
        margin-left: 0;
        margin-right: 5px;
    }

    .rhap_container {
        direction: ltr;
    }

    .rs-checkbox-inline {
        margin-left: 0;
        margin-right: 10px;
    }

    .rs-checkbox-checker {
        padding-left: 0;
        padding-right: 36px;
    }

    .rs-check-item .rs-checkbox-checker {
        padding: 0;
    }

    .rs-check-item .rs-checkbox-checker > label {
        padding: 8px 38px 8px 12px;
    }

    .rs-checkbox-wrapper {
        left: auto;
        right: 10px;
    }

    .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
        left: auto;
        right: 12px;
    }

    .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
        float: right;
    }
    .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
        float: left;
    }

    .rs-table-loader-icon {
        margin-left: 12px;
        margin-right: 0;
    }

    .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
    .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu {
        left: auto;
        right: 0;
    }

    .rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu,
    .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
        left: 0;
        right: auto;
    }

    .rs-dropdown .rs-btn > .rs-icon {
        margin-left: 6px;
        margin-right: 0;
    }

    .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
        margin-left: 0;
        margin-right: 4px;
        left: 16px;
        right: auto;
    }

    .rs-col-xs-1,
    .rs-col-xs-2,
    .rs-col-xs-3,
    .rs-col-xs-4,
    .rs-col-xs-5,
    .rs-col-xs-6,
    .rs-col-xs-7,
    .rs-col-xs-8,
    .rs-col-xs-9,
    .rs-col-xs-10,
    .rs-col-xs-11,
    .rs-col-xs-12,
    .rs-col-xs-13,
    .rs-col-xs-14,
    .rs-col-xs-15,
    .rs-col-xs-16,
    .rs-col-xs-17,
    .rs-col-xs-18,
    .rs-col-xs-19,
    .rs-col-xs-20,
    .rs-col-xs-21,
    .rs-col-xs-22,
    .rs-col-xs-23,
    .rs-col-xs-24 {
        float: right;
    }

    .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
        padding: 7px 11px;
        padding-left: 36px;
    }

    .rs-picker-menu .rs-picker-search-bar::after {
        left: 24px;
        right: auto;
    }

    .rs-picker-menu:not(.no-rtl) {
        text-align: right;
    }

    .rs-picker-default:not(.no-rtl) .rs-picker-toggle,
    .rs-picker-default:not(.no-rtl) .rs-picker-toggle-custom {
        padding-left: 32px;
        padding-right: 11px;
        text-align: right;
    }

    .rs-picker-default:not(.no-rtl) .rs-picker-toggle-caret {
        left: 12px;
        right: auto;
    }

    .rs-form-horizontal .rs-form-group .rs-control-label {
        float: right;
        margin-left: 12px;
        margin-right: 0;
        text-align: right !important;
    }

    .rs-form-horizontal .rs-form-group .rs-form-control-wrapper {
        float: right;
    }

    .rs-picker-toolbar-right {
        float: left;
    }

    .rs-calendar-header-forward::before {
        content: '\ea0a';
    }

    .rs-calendar-header-backward::before {
        content: '\ea0c';
    }


    /* API Page */
    .api_documentation-currentapikey {
        margin-left: 0px;
        margin-right: 40px;
    }
    .api_documentation_list-parameters_list_item_wrapper_item {
        margin-left: 0px;
        margin-right: 20px;
    }
    .api_documentation_list-parameters_list_item_wrapper_item.header {
        margin-right: 0;
    }
    .api_documentation-currentapikey_key_value {
        margin-left: 0px;
        margin-right: 30px;
    }
    .api_documentation_list-method_api_header {
        margin-left: 20px;
        margin-right: 0px;
    }
    .api_documentation_list .api_documentation_list-method_api_link_wrapper .api_documentation_list-method_api_link_wrapper_header {
        margin-left: 20px;
        margin-right: 0px;
    }
    .api_keys-list_item_icon {
        left: 5px;
        right: 0px;
    }
    .api_documentation-tooltip {
        margin-left: 0px;
        margin-right: 10px;
    }
    .api_keys-panel_button_icon {
        margin-left: 0px;
        margin-right: 10px;
    }
    .api_keys-panel_button_generate {
        margin-left: 0px;
        margin-right: 20px;
    }
    .simple_code {
        padding: 20px 40px 0 0px;
    }

    /* Rsuite Tree */

    .rs-tree-node {
        text-align: right;
    }
    .rs-tree-node > .rs-tree-node-label {
        margin: 0;
        padding: 8px 12px;
        padding-right: 24px;
    }
    .rs-tree-node > .rs-tree-node-label::before {
        margin-left: 0;
        margin-right: -24px;
    }
    .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
        padding: 8px 0;
        padding-left: 8px;
        -webkit-transform:  rotate(90deg) translateX(-6px) translateY(-8px) ;
        transform:  rotate(90deg) translateX(-6px) translateY(-8px) ;
        -webkit-transform-origin: 3.5px 16px;
        transform-origin: 3.5px 16px;
        margin-left: 0;
        margin-right: -2px;
    }
    .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon::before {
        content: '\F0D7';
    }
    .rs-tree-open > .rs-tree-node .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        margin-right: 0;
        margin-top: 0;
    }
    .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expanded {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        margin-right: 0;
        margin-top: 0;
    }
    .rs-tree-node-expand-icon-wrapper > .rs-tree-node-custom-icon {
        width: 14px;
        line-height: 36px;
        vertical-align: middle;
    }
    .rs-tree-node-children > .rs-tree-children {
        display: none;
    }
    .rs-tree-open.rs-tree-node-children > .rs-tree-children {
        display: block;
    }

    .rs-tree-node-children .rs-tree-node-active > .rs-tree-node-label {
        border-left: none;
        border-right: 3px solid #20BA88;
    }

    .rs-tree-node-children > .rs-tree-node-active > .rs-tree-node-label {
        border-right: none;
    }

    .rs-tree-node {
        padding-left: 0 !important;
        padding-right: 12px !important;
    }

    .rs-tree-children {

        .rs-tree-node {
            padding-right: 28px !important;
        }

        .rs-tree-children {

            .rs-tree-node {
                padding-right: 44px !important;
            }
        }
    }

    .object-content {
        margin-left: 0 !important;
        margin-right: 6px !important;
    }

    .variable-row,
    .object-key-val {
        padding: 3px 20px 3px 5px !important;
        border-left: none !important;
        border-right: 1px solid rgb(235, 235, 235) !important;
    }

    .react-json-view .collapsed-icon svg {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .rs-table {
        direction: ltr;

        .rs-table-row {
            direction: rtl;
        }
    }

    .rs-picker-toggle-clean {
        left: 30px;
        right: auto;
    }

    .rs-panel-collapsible > .rs-panel-heading::before {
        right: auto;
        left: 20px;
    }

    .rs-table-cell-content {
        direction: ltr;
    }

    .rs-picker-select-menu-item {
        direction: rtl;
    }
}