/* rsuite modal custom styles */

.rs-modal-backdrop.in {
    opacity: 1;
    background-color: rgba(54, 52, 52, 0.8);

    .app-client & {
        opacity: 0.3;
        background-color: #272c36;
    }
}