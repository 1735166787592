/* rsuite form custom styles */

// set field width to fullwidth
// -----------

.rs-form-control-wrapper {
    &,
    & > .rs-input,
    & > .rs-input-number {
        width: 100%;
    }
}
.rs-form-vertical .rs-form-group .rs-input-group {
    width: 100%;
}
.rs-form-inline .rs-form-group .rs-form-control-wrapper {
    width: auto;
}




// set field styles
// -----------

.rs-input,
.rs-form-control-wrapper > .rs-input,
.rs-form-control-wrapper > .rs-input-group,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled) {
    border: 1px solid #e5e5ea;

    .app-client & {
        border: 1px solid var(--color-grey);
    }

    &:hover,
    &:focus {
        border-color: #1675e0;

        .app-client & {
            border-color: var(--color-brand2);
        }
    }

    &::placeholder {
        opacity: 0.75;
    }
}
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled) .rs-input {
    border: none;
}



// set number styles
// -----------

.rs-input-number {

    .rs-input {

        .app-client & {
            background-color: transparent;
        }
    }
}

// set textarea styles
// -----------

textarea.rs-input {
    resize: none;
    min-width: 0;
    min-height: 76px;
    max-width: 100%;
    max-height: 200px;
}



// set pickers styles
// -----------

.rs-picker-default {

    .rs-picker-toggle {

        .rs-picker-toggle-value {

            &,
            & b {
                color: #585858;

                .app-client & {
                    color: var(--color-brand2);
                }
            }
        }

        .rs-picker-toggle-caret {
            color: var(--color-brand2);

            .app-client & {
                color: var(--color-brand1);
            }
        }

        &:focus .rs-picker-toggle-caret {
            color: var(--color-brand2);
        }
    }
}



// set checkbox styles
// -----------

.rs-checkbox-wrapper {

    .rs-checkbox-inner:before {
        background: #C4C4C4;
        border-radius: 5px;
        border: 1px solid #C4C4C4;

        .rs-table-cell-content &,
        .app-client & {
            border: 1px solid #d9d9d9;
            background-color: transparent;
            border-radius: 3px;
        }
    }

    &.rs-checkbox-checked {

        .rs-checkbox-inner:before {
            background-color: var(--color-brand2);
            border-color: var(--color-brand2);
        }
    }
}



// set radio styles
// -----------

.rs-radio-checker {

    .rs-radio-wrapper {

        &:before {
            border: 1px solid var(--color-brand2);

            .app-client & {
                background-color: transparent;
                border-color: var(--color-brand1);
            }
        }

        .rs-radio-inner {

            &:before {
                background: #C4C4C4;
                border: 1px solid #C4C4C4;

                .app-client & {
                    background-color: transparent;
                    border-color: #d9d9d9;
                }

                .rs-radio-checked & {
                    border-color: var(--color-brand2);
                    background-color: var(--color-brand2);

                    .app-client & {
                        border-color: var(--color-brand1);
                        background-color: var(--color-brand1);
                    }
                }
            }
        }

        &:hover [type='radio']:focus ~ .rs-radio-inner::before,
        [type='radio']:focus ~ .rs-radio-inner::before,
        .rs-radio-checked & [type='radio']:focus ~ .rs-radio-inner::before {
            -webkit-box-shadow: 0 0 0 2px rgba(52,152,255,.3);
            box-shadow: 0 0 0 2px rgba(52,152,255,.3);
        }
    }
}



// set calendar styles
// -----------

.rs-picker-menu {

    .rs-calendar-table-cell {

        &.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
            border: 1px solid #3498ff;
            padding: 4px;

            .app-client & {
                border: 1px solid var(--color-brand2);
            }
        }
        &.rs-calendar-table-cell-in-range:before {
            background: rgba(204,233,255,.5);

            .app-client & {
                background: #e6e4f6;
            }
        }

    }

    .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
    .rs-picker-toolbar-right-btn-ok {
        background: #3498ff;
        border-color: #3498ff;

        .app-client & {
            background: var(--color-brand2);
            border-color: var(--color-brand2);
        }
    }

    .rs-picker-toolbar-option {
        color: #085cc9;

        .app-client & {
            color: var(--color-brand2);
        }
    }

    @media only screen and (max-width: 600px) {
        .rs-picker-daterange-menu .rs-calendar:first-child {
            border: none
        }
    }
}



// set form inline styles
// ------------

.rs-form-inline > *,
.rs-form-inline > .rs-btn,
.rs-form-inline > .rs-control-label {
    margin-right: 30px;
}