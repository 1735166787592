.rs-modal .rs-modal-body {
    max-height: 650px !important;
}
.jsoneditor .jsoneditor-poweredBy {
    display: none
}
.jsoneditor .jsoneditor-statusbar {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.jsoneditor-react-container {
    border-radius: 5px;
}
.jsoneditor.jsoneditor-mode-code  {
    border-radius: 5px;
    border: thin solid var(--color-brand1);
}
.jsoneditor.jsoneditor-mode-code .jsoneditor-menu {
    background-color: var(--color-brand1);
    border: 1px solid var(--color-brand1);
}
.jsoneditor .ace_gutter-cell.ace_error,
.jsoneditor .jsoneditor-menu .jsoneditor-format,
.jsoneditor .jsoneditor-menu .jsoneditor-compact,
.jsoneditor .jsoneditor-menu .jsoneditor-sort,
.jsoneditor .jsoneditor-menu .jsoneditor-transform,
.jsoneditor .jsoneditor-menu .jsoneditor-repair {
    display: none;
}

@media (max-width: 768px) {
    div.rs-modal.rs-modal-sm[role="dialog"] {
        margin: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }
    .rs-modal .rs-modal-body {
        width: 100%  !important;
        max-height: 90% !important;
        padding-top: 10px;
    }
    .rs-modal .rs-modal-content {
        height: 100% !important;
        border-radius: 0;
    }
    .rs-modal .rs-modal-dialog {
        height: 100% !important;
    }
    .rs-modal .rs-modal-header {
        top: 10px;
    }
    .rs-modal .rs-modal-title, .rs-modal .rs-modal-header-close {
        color: #575757 !important;
        z-index: 2;
    }
}