div.rs-modal-body.modal-body {
    overflow: hidden !important;
    min-height: 228px !important;
    max-height: 430px !important;
}
div.rs-dropdown .rs-btn.rs-btn-default.rs-dropdown-toggle {
    background: #20BA88;
    padding: 12px 34px;
    color: #fff;
}
body .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
    top: 50%;
    transform: translateY(-50%);
}

.rs-nav-item > a.rs-nav-item-content {
    padding-left: 8px;
    padding-right: 8px;
}
div.rs-modal-footer.modal-footer {
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
}